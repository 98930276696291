import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

let settings = {"environment":"production","appVersion":"10.3.5","releaseStage":"production","apiKey":"d69642eaebbb2729d000b8d0752411ec"}
settings.plugins = [new BugsnagPluginVue()]
let bugsnagClient

export default ({ app }, inject) => {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(settings)
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}
